import React from "react"
import PropTypes from "prop-types"
import { StarWrapper, Input, StyleStar, Focus } from "./Style"

export default function Star({ label, active, isChecked = false, ...props }) {
  return (
    <StarWrapper>
      <Input
        checked={isChecked}
        {...(isChecked ? { checked: true } : { checked: false })}
        type="radio"
        data-label={label}
        {...props}
      />
      <StyleStar
        active={active}
        icon={["fas", "star"]}
        width={30}
        height={30}
      />
      <Focus />
    </StarWrapper>
  )
}

Star.propTypes = {
  name: PropTypes.string.isRequired,
}
