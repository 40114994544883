import React, { useState } from "react"
import {
  InputGroup,
  Label,
  Description,
  StyledTextarea,
  StyledInput,
  StyledFile,
  StyledIcon,
  FileContainer,
  StyledLabel,
  Feedback,
  ImageUploaderContainer,
  ImgPreview,
  Inspiration,
} from "./Style"
import { hasKey } from "../../utils"
import { ButtonSlim } from "../button"
import Link from "../Link"

export function FieldGroup({
  label,
  description,
  htmlFor,
  error,
  feedback,
  children,
  name,
  generator = false,
}) {
  const handleFeedback = (fb = feedback) => {
    const label = fb
      ? Array.isArray(fb)
        ? fb.join(".")
        : fb
      : `Du måste ange ${name}`

    return label
  }

  return (
    <InputGroup>
      {label && <Label htmlFor={htmlFor}>{label}</Label>}
      {description && <Description>{description}</Description>}
      {children}
      {error && <Feedback>{handleFeedback()}</Feedback>}
      {generator && (
        <Inspiration>
          Svårt att komma på ett bra maskinnamn? {" "}
          <Link to="/namegenerator" external={"true"}>
            Få inspiration med vår namngenerator!
          </Link>
        </Inspiration>
      )}
    </InputGroup>
  )
}

export function Textarea({
  label,
  description,
  feedback,
  error,
  errors = {},
  ...props
}) {
  return (
    <FieldGroup
      label={label}
      htmlFor={"s-" + props.name}
      name={props.name}
      description={description}
      feedback={
        !feedback && hasKey(errors, props.name) ? errors[props.name] : feedback
      }
      error={!error && hasKey(errors, props.name) ? true : error}
    >
      <StyledTextarea
        id={"s-" + props.name}
        as="textarea"
        rows="5"
        error={!error && hasKey(errors, props.name) ? true : error}
        {...props}
      />
    </FieldGroup>
  )
}

export function Input({
  label,
  description,
  type = "text",
  error,
  feedback,
  errors = {},
  generator,
  ...props
}) {
  return (
    <FieldGroup
      label={label}
      htmlFor={"i-" + props.name}
      name={props.name}
      description={description}
      error={!error && hasKey(errors, props.name) ? true : error}
      feedback={
        !feedback && hasKey(errors, props.name) ? errors[props.name] : feedback
      }
      generator={generator}
    >
      <StyledInput
        type={type}
        id={"i-" + props.name}
        error={!error && hasKey(errors, props.name) ? true : error}
        {...props}
      />
    </FieldGroup>
  )
}

export function File({
  label,
  description,
  filename,
  error,
  feedback,
  errors = {},
  ...props
}) {
  return (
    <FieldGroup
      label={label}
      htmlFor={"if-" + props.name}
      name={props.name}
      description={description}
      error={!error && hasKey(errors, props.name) ? true : error}
      feedback={
        !feedback && hasKey(errors, props.name) ? errors[props.name] : feedback
      }
    >
      <StyledFile type="file" id={"if-" + props.name} {...props} />
      <FileContainer
        as="div"
        error={!error && hasKey(errors, props.name) ? 1 : error}
      >
        <StyledLabel htmlFor={"if-" + props.name}>
          {filename || "Klicka här för att lägga till bild"}
          <StyledIcon
            error={!error && hasKey(errors, props.name) ? 1 : error}
            icon={["fa", "image"]}
          />
        </StyledLabel>
      </FileContainer>
    </FieldGroup>
  )
}

export function ImageUploader({
  src,
  children,
  hasUpload,
  onCancel = false,
  ...props
}) {
  const [willChange, setWillChange] = useState(false)

  return (
    <ImageUploaderContainer active={willChange && !hasUpload}>
      {willChange ? (
        <>
          {children}
          {!hasUpload && (
            <ButtonSlim
              type="button"
              invert
              icon={["far", "times-hexagon"]}
              onClick={() => {
                setWillChange(false)
                onCancel && onCancel()
              }}
            >
              Cancel
            </ButtonSlim>
          )}
        </>
      ) : (
        <>
          <ImgPreview src={src} />
          <ButtonSlim
            type="button"
            icon={["far", "exchange"]}
            onClick={() => setWillChange(true)}
          >
            Edit
          </ButtonSlim>
        </>
      )}
    </ImageUploaderContainer>
  )
}
