import styled from "styled-components"
import { colors, sidePadding, breakpoints } from "../Style"

export const Container = styled.div`
  margin-bottom: ${props => (props.space ? sidePadding * 2 : 0.5)}rem;
  text-align: center;
`
export const Label = styled.label`
  display: block;
  margin: -0.9rem 0 1.4rem;
  color: ${colors.white};
  font-size: 1.8rem;
  font-weight: 600;

  @media ${breakpoints.medium} {
    margin-top: 0;
    font-size: 2rem;
  }
`
