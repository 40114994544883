import React from "react"
import { useStateValue } from "../../../state/"
import { Title, Preamble } from "./Style"
import Hr from "../../hr"
import useMobile from "../../../lib/useMobile"

export default function FormHeader() {
  const [state] = useStateValue()
  const [isMobile] = useMobile()
  const { step, length } = state.form

  const handleText = () => {
    switch (step) {
      case 1:
        return (
          <>
            {`Dela din maskin, din verklighet, bland andra som fattar känslan. Vi samlar alla maskiner i ett maskinotek som bjuder på fakta och inspiration, direkt från den oslagbara verkligheten. Sätt betyg och fyll i uppgifterna så är du snart med i gänget.`}
          </>
        )
        break
      case 2:
        return `Snyggt! Snart klart! Steg ${step} av ${length}`
        break
      case 3:
        return `Steg ${step} av ${length}. Då så! Bara några klick kvar`
        break

      default:
        break
    }
  }

  return (
    <>
      <Title>Dela med dig av din Epiroc – få keps och t-shirt</Title>
      <Preamble>{handleText()}</Preamble>
      {isMobile && <Hr />}
    </>
  )
}
