import React from "react"
import { Container, Label } from "./Style"
import Star from "../star"

export default function Rating({ value, label, length = 5, ...props }) {
  return (
    <Container space={value !== 0 ? 1 : 0}>
      <Label>{label}</Label>
      {Array.apply(null, { length }).map((e, i) => (
        <Star
          key={"rs" + i}
          active={i < value ? 1 : 0}
          value={i + 1}
          isChecked={value === i + 1}
          title={`${i + 1} av ${length}`}
          aria-label={`${i + 1} av ${length}`}
          {...props}
        />
      ))}
    </Container>
  )
}
