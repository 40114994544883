import styled from "styled-components"
import Icon from "../Icon"
import { CommonInputStyle } from "../input/Style"
import { sidePadding, colors } from "../Style"

export const StyledSelect = styled(CommonInputStyle)`
  border-radius: 0;
  appearance: none;
  padding-right: ${sidePadding + 1.7 + 1}rem;

  option {
    /* fix for windows */
    color: ${colors.dark};
  }
`

export const Container = styled.div`
  position: relative;
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${sidePadding}rem;
  color: ${props => (props.error ? colors.error : "currentColor")};
  transform: translateY(-50%);
`
