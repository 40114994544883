import React from "react"
import SEO from "../components/seo"
import Form from "../components/form/"
import { Block } from "../components/Block"

const AddMachine = () => {
  return (
    <>
      <SEO title="Lägg till din masking" />
      <Block>
        <Form />
      </Block>
    </>
  )
}

export default AddMachine
