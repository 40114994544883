import styled from "styled-components"
import { colors } from "./Style"

const Hr = styled.hr`
  margin: 1.7rem 0 3.1rem;
  height: 0.1rem;
  border: none;
  background-color: ${colors.gray["500"]};
`

export default Hr
