import React, { useState } from "react"
import { useStateValue } from "../../../state/"
import { Input, Textarea, File } from "../../input/"
import Checkbox from "../../checkbox/"
import Select from "../../select/"
import Hr from "../../hr"
import Rating from "../../rating/"
import { ObjectWithoutKey } from "../../../utils"

export default function FormBody() {
  const [state, dispatch] = useStateValue()
  const [showOtherUseCase, setShowOtherUseCase] = useState(false)
  const [showReview, setShowReview] = useState(false)
  const { step, values, errors } = state.form
  const {
    models,
    model,
    attributes,
    importantAttribute,
    term,
    year,
    review,
    description,
    name,
    company,
    machineName,
    email,
    city,
    file,
    accept,
    useCase,
    useCases,
    otherUseCase,
    rating,
  } = values

  const handleErrorInput = name => {
    if (errors.hasOwnProperty(name)) {
      dispatch({
        type: "FORM_ERROR_UPDATE",
        payload: ObjectWithoutKey(errors, name),
      })
    }
  }

  const handleOnChange = event => {
    const { name, value } = event.target

    handleErrorInput(name)

    dispatch({
      type: "FORM_UPDATE",
      payload: {
        target: name,
        value,
      },
    })
  }

  const handleOnChangeSelect = event =>
    dispatch({
      type: "FORM_UPDATE",
      payload: {
        target: event.target.name,
        value: parseFloat(event.target.value),
      },
    })

  const handleOnChangeCheckbox = event => {
    handleErrorInput(event.target.name)
    dispatch({
      type: "FORM_UPDATE",
      payload: { target: event.target.name, value: !accept },
    })
  }

  const handleOnChangeFile = event => {
    handleErrorInput(event.target.name)
    dispatch({
      type: "FORM_UPDATE",
      payload: { target: event.target.name, value: event.target.files[0] },
    })
  }

  const handleOnChangeRating = event => {
    setShowReview(true)

    dispatch({
      type: "FORM_UPDATE",
      payload: {
        target: event.target.name,
        value: parseFloat(event.target.value),
      },
    })
  }

  const handleBody = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Hr />
            <Select
              onChange={handleOnChangeSelect}
              value={model}
              name="model"
              label="Maskintyp"
              description="Välj maskintyp för din Epiroc."
              options={models}
              errors={errors}
              required
            />
            <Input
              onChange={handleOnChange}
              value={term}
              name="term"
              label="Maskinmodell"
              description="Fyll i modellbeteckning för din Epiroc."
              errors={errors}
              required
            />
            <Input
              onChange={handleOnChange}
              value={year}
              name="year"
              label="Årsmodell"
              description="Fyll i årsmodell för din Epiroc."
              pattern="[0-9.]+"
              maxlength="4"
              errors={errors}
              required
            />
            <Select
              onChange={event => {
                handleOnChangeSelect(event)
                setShowOtherUseCase(
                  parseFloat(
                    event.target.children[event.target.selectedIndex].value
                  ) === 2 //hardcoded övrigt
                )
              }}
              value={useCase}
              name="useCase"
              label="Användningsområde"
              description="Hur används din Epiroc? Typ av arbete."
              options={useCases}
              errors={errors}
            />
            {showOtherUseCase && (
              <Input
                onChange={handleOnChange}
                value={otherUseCase}
                name="otherUseCase"
                label="Fyll i typ av arbete."
                required
                errors={errors}
              />
            )}
            <Rating
              label="Betygsätt din Epiroc"
              name="rating"
              value={rating}
              onChange={handleOnChangeRating}
              errors={errors}
              required
            />
            {showReview && (
              <Input
                onChange={handleOnChange}
                value={review}
                name="review"
                label={
                  rating > 2
                    ? "Vad gillar du mest med din maskin?"
                    : "Vad skulle kunna bli bättre?"
                }
                description={rating < 3 ? "Berätta om din idé" : null}
                errors={errors}
              />
            )}
            <Hr />
          </>
        )
      case 2:
        return (
          <>
            <Hr />
            <Textarea
              onChange={handleOnChange}
              value={description}
              name="description"
              label="Beskrivning"
              description="Fyll i en beskrivning av din Epiroc."
              errors={errors}
              required
            />
            <Input
              name="name"
              value={name}
              onChange={handleOnChange}
              label="Ditt namn"
              description="Fyll i ditt namn."
              errors={errors}
              required
            />
            <Input
              name="company"
              value={company}
              onChange={handleOnChange}
              label="Företag"
              description="Fyll i ditt företag."
              errors={errors}
            />
            <Input
              name="machineName"
              value={machineName}
              onChange={handleOnChange}
              label="Maskinens namn"
              description="Fyll i namnet för din Epiroc maskin."
              errors={errors}
              generator={"true"}
            />
            <Select
              label="Viktigast för dig och din Epiroc"
              name="importantAttribute"
              onChange={handleOnChangeSelect}
              value={importantAttribute}
              description="Välj vad som är viktigast för dig när du väljer maskin."
              options={attributes}
              errors={errors}
              required
            />
          </>
        )
      case 3:
        return (
          <>
            <Hr />
            <Input
              name="email"
              type="email"
              value={email}
              onChange={handleOnChange}
              label="Din e-post"
              description="Fyll i din e-post."
              errors={errors}
              required
            />
            <Input
              name="city"
              value={city}
              onChange={handleOnChange}
              label="Ort"
              description="Jag och min Epiroc bor i&hellip;"
              errors={errors}
              required
            />
            <File
              name="file"
              onChange={handleOnChangeFile}
              filename={file ? file.name.split("\\").pop() : null}
              label="Ladda upp bild"
              description="Det är alltid roligare med bild! Ladda upp din här(Maxstorlek 2mb. godkända format .pdf, .png, .jpeg)."
              accept="image/*"
              errors={errors}
              required
            />
            <Checkbox
              name="accept"
              checked={accept}
              onChange={handleOnChangeCheckbox}
              label="Jag godkänner att Epiroc lagrar mina personuppgifter i marknadsföringssyfte."
              errors={errors}
              required
            />
          </>
        )
      default:
        return null
    }
  }

  return handleBody()
}
