import styled from "styled-components"
import { breakpoints } from "../../Style"

export const Title = styled.h1`
  margin: -0.3rem 0 0.8rem;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.22;

  @media ${breakpoints.medium} {
    margin: -1rem 0 1rem;
    font-size: 3.2rem;
    line-height: 1.15;
  }
`

export const Preamble = styled.p`
  margin-bottom: 0.3rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;

  @media ${breakpoints.medium} {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
`
