import styled from "styled-components"
import Icon from "../Icon"
import { easings, colors, resetInput } from "../Style"

export const StarWrapper = styled.label`
  position: relative;
  z-index: 0;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 1.4rem;
  }
`

export const Input = styled.input`
  ${resetInput()}
`

export const StyleStar = styled(Icon)`
  display: block;
  width: 3rem;
  height: 3rem;
  color: ${props => (props.active ? colors.yellow : "#DDD9CF")};
  transition: color 0.1s ${easings.appleOut};
  cursor: pointer;
`

export const Focus = styled.div`
  position: absolute;
  z-index: -1;
  display: block;
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
  border-radius: 50%;
  background-color: ${colors.yellow};
  opacity: 0.2;
  content: "";
  transform: scale(0);
  transition: transform 0.1s ${easings.appleOut};

  ${Input}:focus ~ & {
    transform: scale(1);
  }
`
