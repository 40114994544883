import React from "react"
import { InputGroup, Feedback } from "../input/Style"
import { Input, Check, Container, StyledLabel } from "./Style"
import { hasKey } from "../../utils"
import { useStateValue } from "../../state/"

export default function Checkbox({
  label,
  name,
  error,
  feedback,
  errors = {},
  ...props
}) {
  const handleFeedback = (fb = feedback) => {
    return fb || errors[name].join() || "Du måste gödkänna"
  }

  return (
    <InputGroup>
      <Container htmlFor={"cb-" + name}>
        <Input id={"cb-" + name} name={name} type="checkbox" {...props} />
        <Check error={!error && hasKey(errors, name) ? true : error} />
        <StyledLabel
          as="span"
          error={!error && hasKey(errors, name) ? true : error}
        >
          {label}
        </StyledLabel>
      </Container>
      {hasKey(errors, name) || error ? (
        <Feedback>{handleFeedback()}</Feedback>
      ) : null}
    </InputGroup>
  )
}
