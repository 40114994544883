import React from "react"
import { StyledSelect, Container, StyledIcon } from "./Style"
import { FieldGroup } from "../input"
import { hasKey } from "../../utils"

export default function Select({
  label,
  description,
  error,
  feedback,
  options = [],
  errors = {},
  ...props
}) {
  return (
    <FieldGroup
      label={label}
      description={description}
      error={!error && hasKey(errors, props.name) ? 1 : error}
      feedback={feedback}
    >
      <Container>
        <StyledIcon
          error={!error && hasKey(errors, props.name) ? 1 : error}
          icon={["fa", "chevron-down"]}
          width="1.7rem"
        />
        <StyledSelect as="select" error={error} {...props}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </StyledSelect>
      </Container>
    </FieldGroup>
  )
}
