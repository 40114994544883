export function addNewPost(data) {
  const formData = new FormData()

  Object.entries(data).forEach(([key, val]) => {
    formData.append(key, val)
  })

  return fetch(`${process.env.GATSBY_API_URL}/api/posts`, {
    method: "POST",
    body: formData,
  }).then(response => response.json())
}
