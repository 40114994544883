import styled from "styled-components"
import { Text } from "../../Text"
import { sidePadding, colors } from "../../Style"

export const Block = styled.div`
  padding-left: ${sidePadding}rem;
  margin-bottom: ${sidePadding * 2}rem;
  border-left: 0.4rem solid ${colors.error};
  color: ${colors.error};
`

export const Intro = styled(Text)``

export const List = styled.ul`
  margin-top: ${sidePadding}rem;
  list-style: disc;
  list-style-position: inside;
`

export const Item = styled(Text)``
