import React, { useEffect, useRef } from "react"
import { addNewPost } from "../../api"
import { navigate } from "gatsby"
import { useStateValue } from "../../state/"
import useMobile from "../../lib/useMobile"
import { hasKey } from "../../utils"
import ErrorSummary from "./error"
import FormHeader from "./header"
import FormBody from "./body"
import FormActions from "./actions"
import { Form } from "./Style"

export default function AddMachine() {
  const [isMobile] = useMobile()
  const [state, dispatch] = useStateValue()
  const { submit, values, errors } = state.form

  const formRef = useRef()

  const handleErrors = err => {
    window.scroll({
      top: formRef.current.scrollTop,
      behavior: "smooth",
    })

    dispatch({
      type: "FORM_ERROR_SET",
      payload: err,
    })
  }

  const handleSuccess = () => {
    navigate(`/success`)
  }

  const submitForm = async () => {
    const response = await addNewPost(values)
    hasKey(response, "errors") ? handleErrors(response.errors) : handleSuccess()
  }

  useEffect(() => {
    if (submit) {
      submitForm()
    }
  }, [submit])

  useEffect(() => {
    // set form step 1 on load - for page change
    dispatch({ type: "FORM_SET_STEP", payload: 1 })
    dispatch({ type: "FORM_SET", payload: { form: formRef.current } })
  }, [dispatch])

  return (
    <Form autoComplete="off" ref={formRef}>
      <FormHeader />
      <ErrorSummary errors={errors} />
      <FormBody />
      {!isMobile && <FormActions />}
    </Form>
  )
}
