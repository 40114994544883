import styled, { css } from "styled-components"
import Icon from "../Icon"
import { hexToRgb } from "../../utils/"
import {
  colors,
  sidePadding,
  easings,
  visuallyhidden,
  breakpoints,
} from "../Style"
import { Text } from "../Text"

export const Label = styled.label`
  display: block;
  margin-bottom: 1rem;
  color: ${colors.white};
  font-size: 1.8rem;
  font-weight: 600;
`

export const CommonInputStyle = styled.input`
  width: 100%;
  padding: 1.3rem ${sidePadding}rem;
  border: 0.2rem solid ${props => (props.error ? colors.error : colors.white)};
  color: ${props => (props.error ? colors.error : colors.gray["100"])};
  font-size: 1.6rem;
  font-weight: 500;
  background: transparent;
  box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.05);
  transition: border-color 0.1s ${easings.appleOut};

  @media ${breakpoints.medium} {
    font-size: 1.8rem;
    padding: 1.4rem ${sidePadding}rem 1.7rem;
  }

  &:focus {
    outline: none;
    border-color: ${props => (props.error ? colors.error : colors.yellow)};
    background-color: rgba(${hexToRgb(colors.white).rgb}, 0.05);
  }
`

export const StyledInput = styled(CommonInputStyle)``

export const StyledTextarea = styled(CommonInputStyle)`
  height: auto;
  resize: none;
  min-width: 100%;
  max-width: 100%;
`

export const StyledFile = styled.input`
  ${visuallyhidden()}
`

export const FileContainer = styled(CommonInputStyle)`
  position: relative;

  ${StyledFile}:focus ~ & {
    border-color: ${props => (props.error ? colors.error : colors.yellow)};
    background-color: rgba(${hexToRgb(colors.white).rgb}, 0.05);
  }
`

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const StyledIcon = styled(Icon)`
  width: 2rem;
  color: ${props => (props.error ? colors.error : colors.yellow)};
`

export const Description = styled.p`
  color: ${colors.gray["100"]};
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
`

export const InputGroup = styled.div`
  position: relative;
  margin-bottom: 3.2rem;
  text-align: left;
`

export const Feedback = styled.p`
  margin-top: 1rem;
  color: ${colors.error};
  font-size: 1.6rem;
`

export const ImageUploaderContainer = styled.div`
  margin-bottom: 3.2rem;
  ${props =>
    props.active
      ? css`
          padding: 3.2rem;
          background-color: #2f2f2d;
        `
      : ""}
`

export const ImgPreview = styled.img`
  margin-bottom: 1.6rem;
`

export const Inspiration = styled(Text)`
  margin-top: 1.6rem;
`
