import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Block, Intro, List, Item } from "./Style"

export default function ErrorSummary({ errors = {}, ...props }) {
  const data = useStaticQuery(graphql`
    query ErrorIntro {
      allContentfulFormError {
        nodes {
          intro
        }
      }
    }
  `)

  return (
    Object.keys(errors).length > 0 && (
      <Block {...props}>
        <Intro>{data.allContentfulFormError.nodes[0].intro}</Intro>
        <List>
          {Object.keys(errors).map(k =>
            errors[k].map((v, i) => (
              <Item as="li" key={`${k}-${i}`}>
                {v}
              </Item>
            ))
          )}
        </List>
      </Block>
    )
  )
}
