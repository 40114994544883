import styled from "styled-components"
import { Label } from "../input/Style"
import { colors, visuallyhidden } from "../Style"
import { hexToRgb } from "../../utils/"

export const Container = styled(Label)`
  position: relative;
  cursor: pointer;
  padding-left: ${2 + 0.9}rem;
`

export const Input = styled.input`
  ${visuallyhidden()}
`

export const Check = styled.span`
  position: absolute;
  left: 0;
  display: block;
  width: ${2 - 0.2}rem;
  height: ${2 - 0.2}rem;
  border: 0.1rem solid ${props => (props.error ? colors.error : colors.yellow)};
  border-radius: 0.2rem;
  user-select: none;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1.15rem;
    height: 0.7rem;
    margin-top: -0.15rem;
    border-left: 0.2rem solid ${colors.gray["700"]};
    border-bottom: 0.2rem solid ${colors.gray["700"]};
    transform: translate(-50%, -50%) rotate(-45deg);
    content: "";
    opacity: 0;
  }

  ${Input}:focus ~ &,
  ${Input}:focus:checked ~ & {
    background-color: rgba(${hexToRgb(colors.white).rgb}, 0.05);
  }

  ${Input}:focus:checked ~ & {
    &::before {
      border-left-color: ${colors.yellow};
      border-bottom-color: ${colors.yellow};
    }
  }

  ${Input}:checked ~ & {
    background-color: ${colors.yellow};

    &::before {
      opacity: 1;
    }
  }

  ${Input}:indeterminate ~ & {
    &::before {
      height: 0;
      opacity: 1;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
`

export const StyledLabel = styled.span`
  color: ${props => (props.error ? colors.error : colors.yellow)};
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 500;
`
